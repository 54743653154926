@import '../../styles/config';

.kanaban-wrapper {
    // height: 60vh;
}

.kanaban {
    &__card {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: white;
        border-radius: $border-radius-small;
        // padding: 10px;
        // gap: 5px;
        box-shadow: 0px 0px 8px 2px #D3D3D380;
        cursor: pointer;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid $border-color-base;
            padding: 3px;

            &:empty {
                display: none;
            }

            .priority {
                background: lighten($primary-color, 35%);
                padding: 8px;
                font-size: 13px;
                color: #EE3741;
                margin: 0;
                font-weight: 600;
                width: 100%;
                text-align: center;
                border-radius: $border-radius-small $border-radius-small 0 0;
            }

            .priority.upcoming {
                background-color: #e8f8ee;
                padding: 2px;
                font-size: 13px;
                color: #50b27d;
                margin: 0;
                font-weight: 600;
                width: 100%;
                text-align: center;
                border-radius: 8px 8px 0 0;
            }

            .priority.delayed {
                background: lighten($primary-color, 35%);
                padding: 2px;
                font-size: 13px;
                color: #EE3741;
                margin: 0;
                font-weight: 600;
                width: 100%;
                text-align: center;
                border-radius: $border-radius-small $border-radius-small 0 0;
            }

            .projectID {
                background: #EBF2FC;
                padding: 5px;
                font-size: 13px;
                border-radius: 5px;
                font-weight: 700;
                color: #4178D1;
                // margin: 0% auto;
                width: -moz-fit-content;
                width: fit-content;
                line-height: 1;
            }

            .status-tag {
                line-height: 1;
                padding: 5px;
            }

        }

        &__body {
            padding: 10px;

            .upperBody {
                width: 100%;
                display: flex;
                margin: 8px 0px;
                justify-content: space-between;
            }

            .invoice-number {
                font-weight: 600;
                color: gray;

            }

            .exec {
                background-color: #FEF7E1;
                color: #C79335;
                padding: 0px 7px;
                border-radius: 5px;
                font-weight: 700;
            }

            .source {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

            }

            .project-country {
                img {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                }
            }

            .itemName {
                padding: 3px;
                color: #5A5A5A;
                font-weight: 400;
                font-size: 14px;
            }

            .numberIcon {
                width: 35%;
                display: flex;
                justify-content: space-between;
            }

            .customer-name {
                font-size: 1em;
            }

            .details {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            .payment {
                color: #4178D1;
                font-size: 12px;
            }

            .payment_value {
                color: #4178D1;
                font-size: 14px;
                font-weight: bold;
            }

            .head {
                font-size: 11px;
                color: #5A5A5A;
                margin: 0;



            }

            .leftHead {
                @extend .head;
                text-align: end;
            }

            .value {
                font-size: 13px;
                font-weight: bold;
                color: #5A5A5A;
                margin: 0;

            }

            .leftValue {
                @extend .value;
                text-align: end;
            }
        }

        &__footer {

            border-top: 0.5px solid $border-color-base;
            padding: 5px 10px;

            .details {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            .payment {
                color: #4178D1;
                font-size: 12px;
            }

            .payment_value {
                color: #4178D1;
                font-size: 14px;
                font-weight: bold;
            }

            .head {
                font-size: 11px;
                color: #5A5A5A;
                margin: 0;



            }

            .leftHead {
                @extend .head;
                text-align: end;
            }

            .value {
                font-size: 13px;
                font-weight: bold;
                color: #5A5A5A;
                margin: 0;

            }

            .leftValue {
                @extend .value;
                text-align: end;
            }
        }
    }
}