@import "./../../styles/config";

.wrapper {
    background-color: rgba($secondary-color, 0.1);
    margin-bottom: 3px;
    padding: 0.5rem;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;

    &Read {
        @extend .wrapper;
        opacity: 0.5;
    }

    &:not(&Read):hover {
        transform: scale(1.01);
        transition: transform 0.3s ease-in-out;
    }

    .title {
        font-weight: bold;
    }
}