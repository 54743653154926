.specifications {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: var(--space-small);
    margin: 2rem auto;

    .name {
        color: var(--color-light-black);

        &:after {
            content: ':';
        }
    }

    .value {
        font-weight: 500;
    }
}