@import "../../../styles/config";

.project-item-overview {
    .project-item-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .project-item-index {
        padding: 0.25rem 0.5rem;
        background: white;
        margin-right: 0.5rem;

        // &:hover {
        //     cursor: pointer;
        // }
    }

    .project-item-name {
        // &:hover {
        //     cursor: pointer;
        // }
    }

    .project-item-actions {
        text-align: right;
        display: flex;
        gap: 0.5em;
        flex-direction: row;
        justify-content: flex-end;

        &--action {
            background: #e4e4e4;
            border-radius: 3px;
            padding: 0.4em 1em;
            color: #000;

            &:hover {
                cursor: pointer;
            }

            &.project-item-date {
                flex-shrink: 0;

                &:hover {
                    cursor: default;
                }
            }

            &.project-item-edit * {
                color: $secondary-color;
            }

            &.project-item-delete * {
                color: $primary-color;
            }
        }
    }
}