.vendors {
    &Header {
        display: grid;
        grid-template-columns: 50px repeat(auto-fit, minmax(0, 1fr));
        align-items: center;

    }

    .vendor {
        cursor: pointer;

        &Selected {
            @extend .vendor;
            box-shadow: 1px 1px 2px 1px #cabdbd;
        }

        &Details {
            display: grid;
            grid-template-columns: 50px repeat(auto-fit, minmax(0, 1fr));
            align-items: center;
            font-weight: 500;
        }
    }
}

// .projectModal {
//     padding: 32px;
//     box-shadow: inset 0px 0px 20px 2px lightgray;
//     border-radius: 16px;

// }

.usergrid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 2fr);
}