@use '../../../css/box';

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.deliveryRowHead {
    display: grid;
    grid-template-columns: 60px 3fr 1fr 1fr auto;
    justify-items: stretch;
    gap: 1rem;

    &WithComment {
        @extend .deliveryRowHead;
        grid-template-columns: 60px 1fr 1fr 2fr;
    }
}

.deliveryRow {
    // display: grid;
    // grid-template-columns: repeat(6, 1fr);
    // justify-items: start;
    // gap: 1rem;
    // align-items: center;
    @extend .deliveryRowHead;
    @extend .box--blue;

    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    &WithComment {
        @extend .deliveryRow;
        @extend .deliveryRowHeadWithComment;
    }
}