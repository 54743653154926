@import "../../styles/config";

.pageWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 75vw;
  min-height: 75vh;
  align-items: center;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 0px 10px 0px $gray1;
}

.loginWrapper {
  padding: 50px;
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  .logo {
    width: 80%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  .loginButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.imageWrapper {
  background-color: $primary-color;
  width: 100%;
  height: 100%;
  background-image: url(../../../public/images/login.jpg);
  background-size: cover;
}