.container {
    background: rgba(246, 246, 246, 0.76);
    padding: 1em;
    border-radius: 15px;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &:first-child {
        border-radius: 0 0 15px 15px;
    }

    .vendorDetails {
        width: 100%;
        text-align: left;
        table-layout: fixed;
    }


}

.quantityPricesHead {
    display: grid;
    grid-template-columns: repeat(var(--columns, 8), 1fr);
    align-items: center;
    padding: 0 1rem;
    gap: 0.5rem;

    &--requireFinisher {
        @extend .quantityPricesHead;
        // grid-template-columns: repeat(9, 1fr);
    }
}

.confirmationBox {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 14px 9px #e5e5e5 inset;
    text-align: left;
}

.buttonRow {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

}

.quotationReference {
    //    width: 100% !important;
    display: flex !important;
}

.vendorInfo {
    padding: 16px;
    text-align: center;
    display: grid;
    align-items: center;
    border-radius: 16px;
    box-shadow: inset 0px 0px 20px 12px lightgray;
    justify-items: start;
    justify-content: space-between;
}

.vendorPriceRow {
    display: grid;
    grid-template-columns: repeat(var(--columns, 8), 1fr);
    background: #f1f1f1;
    border-left: 1px solid #8f8f8f;
    padding: 8px;
    margin-top: 8px;
    gap: 0.5rem;

    &--requireFinisher {
        @extend .vendorPriceRow;
        // grid-template-columns: repeat(9, 1fr);
    }
}

.selectedVendor {
    background-color: #d4f1d4;
    border-radius: 8px;
    padding: 8px;
}

.statusBox {
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    margin-top: 16px;
    box-shadow: 0px 0px 20px 0px lightgray;
    line-height: 8px;

}