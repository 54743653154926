.dropArea {
    display: flex;
    border-radius: var(--radius-small);
    border: 1px var(--color-silver-line) solid;
    justify-content: space-between;
    gap: var(--space-large);
    /* padding: var(--space-large); */
    flex-direction: row;
    align-items: center;
    margin: 16px;
    /* padding: 32px; */
    width: 12%;

    .dropText {
        font-size: var(--fontsize-large);
        font-weight: bold;
    }

    .dropIcon {
        border: 1px dashed lightgrey;
        text-align: center;
        padding: 16px 32px;
        font-size: 32px;
        color: lightgray;
        margin: 16px;
        cursor: pointer;
    }

    .uploadButton {
        cursor: pointer;
    }
}

.files {
    &:empty {
        display: none;
    }

    display: flex;
    width: 100%;
    align-items: center;
    min-width: 0;
    padding: 16px;
    flex-wrap: wrap;
    background: #f6f6f6;
    align-content: flex-start;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    gap: 1rem;

    .label {
        width: 50%;
        text-align: start;
    }


    .file {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto auto;
        gap: 10px;
        align-items: center;
        width: 100%;

        .filePreview {
            grid-row: 1 / 4;
        }

        .fileTitle {
            grid-column: 2;
            grid-row: 1;
        }

        .fileComment {
            grid-column: 2;
            grid-row: 2;
        }

        .fileActions {
            grid-column: 2;
            grid-row: 3;
        }

        img {
            display: block;
            width: 100px;
            height: 100px;
            object-fit: cover;
            border: 0.5px solid gray;
            padding: 8px;
            background: white;
            border-radius: 8px;
        }
    }
}