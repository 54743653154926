@import './../../styles/config';

.container {
    border-radius: $border-radius-large;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .header {
        padding: 8px 15px;
        display: flex;
        gap: 1rem;
        font-weight: 500;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .title {
            color: #5a5a5a;
        }

        .icon {
            font-size: 1.2rem;
        }
    }

    .content {
        padding: 15px;
        font-weight: 600;
        font-size: 1.75rem;
    }

    &.blueBox {
        background-color: $blue-light-color;
        border-color: $blue-light-color;
    }

    &.grayBox {
        background-color: #F5F5F5;
        border-color: #F5F5F5;
    }

    &.grayBox2 {
        background-color: $gray-ligh2;
        border-color: $gray-ligh2;
        color: $secondary-color;
    }
}