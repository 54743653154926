.card {
    cursor: pointer;


    &--selected,
    &:hover {
        box-shadow: 1px 1px 4px -1px #ccc;
    }

    &Normal {
        cursor: initial;

        &:hover {
            box-shadow: none;
        }
    }

    .item {
        display: flex;
        justify-content: flex-start;
        gap: 1em;

        .itemLabel {
            font-weight: bold;

            &:after {
                content: ":";
            }
        }
    }
}