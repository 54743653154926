.FormWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1em;

    .FormField {
        &--col1 {
            grid-column: 2 / 2 span;
        }

        &--col2 {
            grid-column: 2 / 2 span;
        }

        &--col3 {
            grid-column: 1 / 3 span;
        }
    }
}

.iconSize{
 font-size: 16px;
}

.mail{
    @extend .iconSize;
    color: #EAB935 !important ;
}
.globe{
    @extend .iconSize;
    color: #6E589D !important;
}
.phone{
    @extend .iconSize;
    color: #4178D1 !important;
}

.projectTable {
    clear: both;
    max-width: 100%;
    margin-top: 41px;
    position: fixed;
}