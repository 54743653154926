.innerBox{
    padding: 15px;
    background-color: #fafafa;
    border-radius: 8px;
  
}

.grayBox{
    padding: 16px;
    border-radius: 8px;
    background-color: #F9F9F9;
    box-shadow: inset 0px 0px 7px 1px silver;
}

.innerReviewWrapper{
    padding: 25px 50px;
    background-color: #F1F1F1;
    border-left: 1px solid darkslategray;
}
.review{
    float: right;
    background: white !important;

}
.first{
    width: 70%;
}
.second{
    width: 27%;
}
.projectTable{
    width: 100%;
}
.Approvebutton{
    float: right;
}

.row1{
    display: flex;
    justify-content: space-between;
}
.row2{
    display: flex;
    width: 40%;
    justify-content: space-between;
}
.outerBox{
    display: flex;
    justify-content: space-between;

}
.grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1em;
}
.blueBox{
    background-color: #ebf2fc;
    padding: 10px;
    display: flex;
    gap: 16px;
    border-radius: 12px;
    justify-content: flex-start;
}
.tableBody{
    background-color: #f3f3f3;
    border-left: 1px solid gray;

}
.cellPading{
    padding: 10px;
}
.tableHead{
    width: 8rem;
    height: 4rem;
    padding: 8px;
    font-weight: 700;
    font-size: 12px;
    border-bottom: 1px solid lightgray;
}
.vendorHead{
    @extend .tableHead ;
    width: 18rem;
  
}
.key{
    color: #3C3C3C;

}
.value{
color: #5A5A5A;
}
.combiProjects{
    padding:  15px 20px;
    background: #f3f3f3;
}

.left{
    padding: 15px 20px;
    background: #f3f3f3;
    border-left: 2px solid gray;

}

.right{
    padding: 15px 20px;
    background: #f3f3f3;
   border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; 
}

.innerWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.combiProjectsHead{
    padding:  15px 20px ;
}
.wrapper{
    background-color: white;
    border-radius: 15px;
    padding: 15px;
}

.reviewWrapper {
    background-color: white;
    border-radius: 15px;
    padding: 30px;
}
