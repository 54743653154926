.sider {
  // height: 100vh;
  // flex: 0 0 200px;
  // /* max-width: 200px; */
  // /* min-width: 200px; */
  // position: fixed !important;
  // width: 200px;
  // top: 0;
  // left: 0;
  // z-index: 10;
  // overflow-y: auto;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  position: sticky;
  // top: 0;
  bottom: 0;
  min-height: 100vmin;
  left: 0;
}

.sider::-webkit-scrollbar {
  display: none;
}

.menu {
  padding-left: 0;
}