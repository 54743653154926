.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;

        .card {
            cursor: pointer;

            &--selected,
            &:hover {
                box-shadow: 1px 1px 4px -1px #ccc;
            }

            &--selected {
                background-color: #E8F8EE;
            }

            &--disabled {
                cursor: default;
                box-shadow: none;
                background-color: #E8F8EE;

                &:hover {
                    box-shadow: none;
                }
            }

            .item {
                display: flex;
                justify-content: flex-start;
                gap: 1em;

                .itemLabel {
                    font-weight: bold;

                    &:after {
                        content: ":";
                    }
                }
            }
        }
    }
}

// .customModal{
//     /* Rectangle 7 */

// // position: absolute;
// // width: 693px;
// // height: 248px;
// // left: 251px;
// // top: 263px;

// background: rgba(255, 0, 0, 0.69);
// box-shadow: inset 1px 1px 6px #D9D9D9;
// border-radius: 15px;

// }