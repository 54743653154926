.phone-input {
    &>.ant-input {
        &:has(.ant-input-disabled) {
            border: none
        }
    }

    .ant-input-prefix {
        color: #666
    }

    .ant-select,
    .ant-input-affix-wrapper {
        height: 30px;
        padding: 0;
    }
}