.page {
  padding: 1rem 2em;

  &:has(.kanaban-wrapper, .table--fullwidth) {
    padding: 0;
  }
}

.headerStats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: space-around;
  gap: 1rem;
  z-index: 5;
  background: #f0f2f5;
  width: fit-content;
}

.title-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  h1 {
    margin-bottom: 0;
  }
}