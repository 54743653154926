.dropArea {
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-small);
    border: 1px var(--color-silver-line) solid;
    align-items: center;
    justify-content: center;
    gap: var(--space-large);
    padding: var(--space-large);
    cursor: pointer;

    .dropText {
        font-size: var(--fontsize-large);
        font-weight: bold;
    }
}

.files {
    display: flex;
    width: 100%;
    align-items: center;
    min-width: 0;
    padding: 16px;
    flex-wrap: wrap;
    background: transparent;
    align-content: flex-start;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;


    .file {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-content: flex-end;
        align-items: center;

        img {
            display: block;
            width: 80px;
            height: 100px;
            object-fit: cover;
        }
    }
}

.browse {
    border-radius: 8px;
    background-color: white;
    padding: 8px;
    border: 2px solid #90D3B7;
    color: #90D3B7;
    font-weight: 700;
}