.itemList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
}

.itemDetail {
    display: flex;
    justify-content: space-evenly;
    background-color: #F9F9F9;
    border-radius: 16px;
    padding: 16px;
    align-items: center;

    &Disabled {
        @extend .itemDetail;

        .imageName, .title {
            color: #aaa;
        }
        
        .title h4{
            color: #aaa;
        }

        img {
            opacity: 0.6;
        }

        // opacity: 0.6;
    }
}

.imageBox {
    object-fit: cover;
    height: 100px;
    width: 100px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 2px;
}



.tabBody {
    background: #F9F9F9;
    padding: 32px;
    border-radius: 0px 16px 16px 16px;
}

.rejectTab {
    background: #FCEAE9;
    color: red;
    font-weight: 600;
}

.imageName {
    width: 50%;
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: space-between;

}

.title{
    width: 200px;
    overflow: hidden;
}

.ant-input[disabled] {
    color: #000;
    background-color: #eaeaea7a !important;}