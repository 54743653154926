.confirmFileList {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto;
    gap: 10px;
    align-items: center;
    width: 100%;
    border-bottom: 1px #f1dbdb solid;

    .filePreview {
        grid-row: 1 / 4;
    }

    .fileComment {
        grid-column: 2;
        grid-row: 2;
        font-size: 0.6rem;

        .label {
            width: 100%;
            font-weight: 500;
        }

        textarea:disabled {
            margin: 0;
            padding: 0;
        }
    }

    .fileActions {
        grid-column: 2;
        grid-row: 3;
    }

    img {
        display: block;
        object-fit: cover;
        width: 50px;
    }
}