.FormWrapper {
   

    .FormField {
        &--col1 {
            grid-column: 1 / 2 span;
        }
        &--col2 {
            grid-column: 2 / 2 span;
        }
        &--col3 {
            grid-column: 1 / 3 span;
        }
    }
    .ant-checkbox-group{
        display: inline-flex;
    }
}
.company{
    width: 40% ;
}
.fields{
    width: 28%;
}
.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 6.5em;
    gap: 1em;
}
.smallField{
    width: 18%;
}

.halfWidth{
    width: 50%;
}
.thirdWidth{
    width: 33%;
}
.row{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1em;
}
.searchHeader{
    display: flex;
    flex-direction: row-reverse;
    margin: 20px;
    justify-content: space-between
  }