.innerWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.5rem;
    margin-left: auto;

    .header {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

    }

    .footer {
        display: flex;
        justify-content: space-between;
    }
}

.header {

    width: 100%;
    position: sticky;
    top: 0;
    // bottom: 0;
    // top: 64px;
    z-index: 8;
    text-align: right;
    background: #f0f2f5;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    // position: fixed;
    // width: auto;
    // left: 200px;
    // right: 0;
}

.secondaryHeaderWrapper {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .subHeaderWrapper {
        display: flex;
        flex-wrap: wrap
    }
}