@import '../../styles/config';

.mainWrapper {
    border-radius: 15px;
    background: rgba(249, 249, 249, 0.76);
    padding: 15px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &-Header {
        display: flex;
        justify-content: flex-end;
    }
}

.fieldsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;

    &--Two {
        @extend .fieldsWrapper;
        grid-template-columns: repeat(2, 1fr);
    }

    &--Four {
        @extend .fieldsWrapper;
        grid-template-columns: repeat(4, 1fr);
    }

    .inputField {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: space-between;

        &--right {
            @extend .inputField;
            align-items: flex-end;
        }

        &--span2 {
            @extend .inputField;
            grid-column: span 2;
        }

        &--span3 {
            @extend .inputField;
            grid-column: span 3;
        }

    }

    .description {
        display: grid;
        grid-template-columns: 40% auto;

        .DescriptionTitle {
            font-weight: bold;
        }

        &Bubble {
            @extend .description;
            border-radius: 1rem;
            padding: 0.5rem;
            grid-template-columns: 60% auto;
            background-color: white;
            border: 1px $gray-light solid;

            .DescriptionTitle {
                font-weight: 500;
            }
        }

        &--span2 {
            @extend .description;
            grid-column: span 2;
        }

        &--span3 {
            @extend .description;
            grid-column: span 3;
        }
    }
}

.actionsWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 1rem
}