.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;

    .item {
        display: flex;
        justify-content: flex-center;
        gap: 1em;

        .itemValue {
            flex: 1;
        }

        .itemLabel {
            font-weight: bold;
            width: 120px;

            &:after {
                content: ':'
            }
        }
    }

}