.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.greenButton{
    border: 1px solid #50B27D;
    color: #50B27D;
    font-weight: 500;
}