.formWrapper {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    .fieldsWrapper {

        .inputField {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            justify-content: space-between;
        }
    }
}