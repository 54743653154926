.logo-wraper {
  margin: 20px auto;
  width: 100%;
  height: 50px;
  background-image: url(../../../public/images/logo.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.small-logo-wraper {
  margin: 20px auto;
  width: 100%;
  height: 50px;
  background-image: url(../../../public/images/logo_100.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.toggle-theme {
  text-align: center;
}

.user-info {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  .text {
    text-align: center;

    h4 {
      color: #000;
      text-transform: capitalize;
    }

    p {
      color: #8F8F8F;
    }
  }
}