$primary-color: #ee3741;
$secondary-color: #23b17c;
$gray1: #9b9b9b;
$gray-light: #efefef;
$gray-ligh2: #e8f8ee;
$gray-ligh3: #e4e4e4;
$blue-color: #4178d1;
$blue-light-color: #ebf2fc;
$border-color-base: #c1c0c0;
$border-radius-large: 15px;
$border-radius-medium: 10px;
$border-radius-small: 8px;