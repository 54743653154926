.wrapper {
    width: 50%;
    background: #dfdfdf;
    border-radius: 10px;
    justify-items: stretch;
    padding: 15px;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }

    .left {
        &>.title {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            display: inline-block;
        }

        .image {
            img {
                width: 100%;
                max-width: 600px;
                aspect-ratio: 9/6;
                border: 1px var(--color-silver-line) solid;
                border-radius: var(--radius-small);
                max-width: 100%;
                overflow: hidden;
            }
        }
    }

    .right {
        &>.title {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            line-height: 1.2;
            display: inline-block;
        }
        .specificationsTitle{
            display: grid;
            grid-template-columns: repeat(2,1fr);
        }

        .quantities {
            width: 100%;
            text-align: left;
            border-collapse: separate;
            border-spacing: 0 var(--space-small);
            color: var(--color-light-black);

            .quantityHeading {
                color: #000;
            }

            .quantityRow {
                display: grid;
                justify-items: stretch;
                padding: 0.8rem 1.2rem;
                grid-template-columns: repeat(4, minmax(max-content, 1fr));

                &:not(.quantityHeading) {
                    cursor: pointer;
                }

                .quantityCheckbox {
                    text-align: right;
                }

                &:has(input:checked) {
                    box-shadow: 1px 1px 3px var(--color-green);
                    border-radius: var(--radius-small);
                }

                input[type="checkbox"] {
                    cursor: pointer;
                }
            }
        }
    }

}

.item {
    background-color: #EBF2FC !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border-radius: 5px !important;
    width: 200px !important;

}

.itemInfo {
    font-size: 20px;
    padding: 6px;
    margin-right: 10px;
}


.footerButton {
    text-align: center;

    display: inline-block;
}

.stepBody {
    width: 100%;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
}

.cardValue {
    font-weight: 600 !important;
    color: #4178D1;
    font-size: 15px !important;
}

.message {
    text-align: start;
    padding: 30px;
    background: white;
    border-radius: 13px;
    box-shadow: 2px 1px 10px lightgray;
}

.stepBox {
    margin-top: 20px;
    border-radius: 10px;
    background-color: aliceblue;
    padding: 30px;

}

.viewPDF {
    float: right;
    margin-top: 16px;
}

.tabContainer {
    padding: 16px;
    background-color: #F6F6F6;
}

.sendButton {
    float: right;
}