.mainWrapper {
    border-radius: 15px;
    background: rgba(249, 249, 249, 0.76);
    padding: 15px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    // gap: 2rem;

    &-Header {
        display: flex;
        justify-content: flex-end;
    }
}

.ledgerLine {
    display: grid;
    grid-template-columns: 32px 30% 15% 1fr 1fr 1fr 70px 50px;
    gap: 1rem;

    &Head {
        @extend .ledgerLine;
        font-weight: bold;
    }
}