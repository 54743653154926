@statuses: new, sent-to-client, first-reminder, second-reminder, third-reminder, final-reminder, sent-to-euler, waiting-for-invoice, waiting-for-purchase-bill, paid, unpaid;
@bg-colors:#2196F3, #4CAF50, #FF9800, #F57C00, #D32F2F, #B71C1C, #673AB7, #FFC107, #FF9800, #2E7D32;
@text-colors: white, white, white, white, white, white, white, white, white;

// Mixin for dynamic styles
.status-styles(@prefix, @index) when (@index > 0) {
    // Get class name, background color, and text color at the current index
    @status: extract(@statuses, @index);
    @bg-color: extract(@bg-colors, @index);
    @text-color: extract(@text-colors, @index);

    &.@{prefix}--@{status} {
        background-color: @bg-color;
        color: @text-color;
    }

    // Recursive call for the next item in the list
    .status-styles(@prefix, @index - 1);
}

.status-styles-with-child(@prefix, @child, @index) when (@index > 0) {
    // Get class name, background color, and text color at the current index
    @status: extract(@statuses, @index);
    @bg-color: extract(@bg-colors, @index);
    @text-color: extract(@text-colors, @index);

    &.@{prefix}--@{status} @{child} {
        background-color: @bg-color;
        color: @text-color;
    }

    // Recursive call for the next item in the list
    .status-styles-with-child(@prefix, @child, @index - 1);
}