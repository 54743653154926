.headingText {
    font-weight: bold;
}

.additionalQuote {
    text-align: center;
}

.formGrid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 40px;
    gap: 1em;
    flex: 1;
    margin: 0 1rem;
    font-weight: 500;
}

.gridValues {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 40px;
    gap: 1em;
}