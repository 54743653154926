.combiGrid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}

.boxHeader {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .boxTitle {
        display: flex;
        justify-content: space-between;
        flex: 1;
        align-items: baseline;
    }
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;

    &Heading {
        @extend .row;
        padding: 0 1rem;
    }
}