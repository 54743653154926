@use '../../../css/box';

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.deliveryRowHead {
    display: grid;
    grid-template-columns: 60px 1.5fr 1fr 1fr 2fr 1fr 1fr;
    justify-items: stretch;
    gap: 1rem;
}

.deliveryRow {
    // display: grid;
    // grid-template-columns: repeat(6, 1fr);
    // justify-items: start;
    // gap: 1rem;
    // align-items: center;
    @extend .deliveryRowHead;
    @extend .box--blue;
    padding: 0.5rem;
}