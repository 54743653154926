.deliveryRowHead {
    display: grid;
    grid-template-columns: 90px 4fr 1fr 1fr 1fr 50px;
    justify-items: stretch;
    gap: 1rem;
    padding: 0 1rem;
}

.deliveryRow {
    @extend .deliveryRowHead;
    padding: 0;

    &Loading {
        @extend .deliveryRow;
        opacity: 0.7;
        pointer-events: none;
    }
}

.status{
    background: #E8F8EE;
    color: #55C091;
    font-weight: 700;
    padding: 6px;
    border-radius: 5px;
    text-align: center;
}

.formGrid {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 2fr 2fr 3fr;
    gap: 1em;
    flex: 1;
    margin: 0 1rem;
    font-size: 12px;
    font-weight: 500;
}

.gridValues {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 2fr 2fr 3fr;
    gap: 1em;
}