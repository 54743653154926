@import '~antd/dist/antd.less';
@import './_config.less';
@import './_statuses.less';
@color-lightgray-1: #f9f9f9;
@color-lightgray-2: #e8f8ee;
@color-lightgray-3: #e4e4e4;
@process-tail-color: #23b27c; //Move to craco.config
@blue-color: #4178d1; //Move to craco.config
@finish-tail-color: #23b27c; //Move to craco.config
@wait-tail-color: @border-color-base; //Move to craco.config
@process-icon-text-color: #23b27c; //Move to craco.config
@input-hover-border-color: @secondary-color;

.@{table-prefix-cls} {
    &-sticky {
        &-holder {
            background-color: transparent;
        }
    }

}

&.table--fullwidth {
    .@{table-prefix-cls} {
        .ant-table-thead>tr {
            &>th {
                position: sticky !important;
                top: -2px;
                // background: white;
                z-index: 3;

                &.ant-table-cell-fix-left {
                    z-index: 4;
                }
            }
        }
    }
}

.@{tab-prefix-cls} {

    &-top,
    &-bottom {

        >.@{tab-prefix-cls}-nav,
        >div>.@{tab-prefix-cls}-nav {
            margin: 0;

            &::before {
                border: none
            }

            .@{tab-prefix-cls}-ink-bar {
                height: 100%;
                top: 0;
                z-index: -1;
                border-radius: @table-border-radius-base @table-border-radius-base 0 0;
                padding: 10px;
                margin-left: -10px;
                box-sizing: content-box;
            }
        }

    }

    &-tab {
        border-radius: @border-radius-base;
        background: @color-lightgray-2;
        padding: 8px 16px;

        // &&-active {
        //     border-radius: 10px 10px 0px 0;
        // }

        // &+& {
        //     //margin: 0;
        // }
    }

    .@{tab-prefix-cls}-nav-list {
        padding-top: 10px;
        padding-bottom: 13px;
        padding-left: 10px;
    }

    &-content {
        &-holder {
            padding: 15px;
            background-color: @color-lightgray-1;
            border-radius: 0 @table-border-radius-base @table-border-radius-base @table-border-radius-base;
        }
    }
}


.@{list-prefix-cls} {
    &-item {
        &-right &-meta {
            display: flex;
            flex-direction: row-reverse;
            gap: 1rem;

            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

            }

            &-title {
                display: flex;
                flex-direction: row-reverse;
                align-items: flex-end;

                .time {
                    padding-right: 2em;

                }
            }
        }

        &-meta {
            &-title {
                .time {
                    font-size: .8em;
                    font-weight: normal;
                    padding-left: 2em;
                    color: #555;
                }
            }
        }
    }
}

.ant-card-body {

    padding: 0px 0px 6px 10px !important;
}

.ant-card-head {

    padding: 0px 0px 0px 10px !important;
}

.ant-checkbox-group.toggle-token label.ant-checkbox-wrapper {
    border: 1px #e5dcdc solid;
    border-radius: 5px;
    padding: 5px;
    background: #f3f3f3;

    &:has(input:checked) {
        background: #cac6c6;
    }

    .ant-checkbox-inner {
        display: none
    }
}

.ant-card-meta-title {
    margin: 0 !important;
}

.ant-collapse-header-text {
    text-align: start;
}

.@{steps-prefix-cls}-item {
    &-tail {
        &::after {
            height: 1.5px;
        }
    }

    &-title {
        font-size: 13px;
        line-height: 16px;
    }

    &[dotted=yes] &-tail::after {
        // height: 0;
        // border: 1px @process-tail-color dashed;
        // background: none;

        height: 1px;
        background-color: transparent;
        background-image: linear-gradient(to right, @process-tail-color 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 7px 1px;
        background-repeat: repeat-x;
        border: none;
    }

    &-active {
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            border: solid 32px transparent;
            position: absolute;
            border-bottom-color: @border-color-base;
            transform: scaleX(0.5) translate(-50%, 15%);
            bottom: -50%;
            left: 50%;
            margin-left: -20%;
            margin-bottom: -1px;
        }

        &:after {
            content: "";
            border: solid 32px transparent;
            position: absolute;
            border-bottom-color: #fff;
            transform: scaleX(0.5) translate(-50%, 15%);
            bottom: -50%;
            left: 50%;
            margin-left: -20%;
            margin-bottom: -2px;
        }
    }
}

.ant-alert-info {
    background-color: #EBF2FC;
    border: 1px solid #ffffff;
}

// .ant-alert-warning {
//     display: none;
// }

// .ant-steps-item>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
//     background: transparent !important;
// }

// .ant-steps-item-container {

//     align-items: center !important;

// }

// .ant-steps-item-title {

//     position: relative;
//     display: inline-block;

//     color: rgba(0, 0, 0, 0.85);
//     font-size: 13px;
//     line-height: 16px;

// }

.@{menu-prefix-cls} {
    &-submenu a {
        color: @menu-item-color;
    }

    &-root&:not(&-horizontal)>&-item-selected,
    &-root&:not(&-horizontal)>&-submenu-selected &-submenu-title {
        background-color: @primary-color;

        &,
        .ant-menu-submenu-arrow,
        a {
            color: white;
        }
    }

    &-item:not(&-item-selected),
    &-submenu:not(&-submenu-selected)>&-submenu-title {
        &:hover {

            &>.ant-menu-item-icon,
            &>.ant-menu-title-content>a {
                color: red;
            }
        }
    }

    &-vertical,
    &-vertical-left,
    &-vertical-right,
    &-inline {
        border-right: none;
    }


    &-inline&-root &-item,
    &-inline&-root &-submenu-title {
        width: 96%;
        border-radius: 10px;
    }

    &-inline &-submenu {
        width: 96%;
        border-radius: 10px;
        margin-left: 5px;
    }

}


// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {

//     color: #37B27C;
//     font-weight: 600;
//     font-size: 16px;
//     text-shadow: 0 0 0.25px currentcolor;
// }

// .ant-tabs-tab {
//     position: relative;
//     display: inline-flex;
//     align-items: center;
//     padding: 0px 0;
//     font-size: 14px;
//     background: transparent;
//     border: 0;
//     outline: none;
//     cursor: pointer;
// }

// .ant-tabs-tab-btn {
//     position: relative;
//     display: inline-flex;
//     /* align-items: revert; */
//     padding: 12px;
//     width: 200px;
//     font-size: 14px;
//     background: #E8F8EE;
//     border-radius: 11px 11px 0px 0px;
//     border: 0;
//     outline: none;
//     cursor: pointer;
// }

.@{input-prefix-cls} {
    &-group-compact {
        .ant-form-item {
            margin-top: 16px;

            &:first-child {

                .@{ant-prefix}-input-affix-wrapper,
                .@{input-prefix-cls},
                .@{select-prefix-cls}-selector {
                    border-radius: @control-border-radius 0 0 @control-border-radius;
                }
            }

            &:last-child {

                .@{ant-prefix}-input-affix-wrapper,
                .@{input-prefix-cls},
                .@{select-prefix-cls}-selector {
                    border-radius: 0 @control-border-radius @control-border-radius 0;
                }
            }
        }
    }
}

.@{input-prefix-cls}-number {
    &-affix-wrapper {
        &.currency-input {
            align-items: baseline;

            &.has-prefix {
                .ant-input-number-prefix {
                    margin-inline-end: 0
                }
            }
        }
    }
}

.@{radio-prefix-cls} {
    &-group-solid {
        .@{radio-prefix-cls}-button-wrapper {
            &-checked:not(&-disabled) {
                background: @btn-primary-bg;
                border-color: @btn-primary-bg;

            }
        }
    }
}

.@{btn-prefix-cls} {

    //Disable clicks on loading buttons
    &-loading {
        pointer-events: none;
    }

    &-icon-only,
    &-actionpanel:not(&-icon-only) {
        &.@{btn-prefix-cls}-square {
            padding: 0.5rem;
            border-radius: 3px;

            &.@{btn-prefix-cls}-background-ghost {
                border: none;
                background-color: @color-lightgray-3;
                color: #000;

                &.@{btn-prefix-cls}-dangerous {
                    color: @primary-color;
                }

                &.@{btn-prefix-cls}-primary {
                    color: @blue-color;
                }

                &.@{btn-prefix-cls}-actionpanel {
                    line-height: 1;
                    font-weight: 400;
                }
            }
        }
    }

    &-critical {
        background-color: orange;
        color: white;
    }

    &-link {
        color: @blue-color;
    }
}

// .ant-radio-button-wrapper-disabled:first-child {
//     background: #23B27C;
//     border-color: #23B27C;
// }
.ant-radio-group {
    font-size: 13px;
}

.ant-input-group-compact .ant-form-item {
    margin-top: 0;
}



.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: #fff;
    background: #23B27C;
    border-color: #23B27C;
    box-shadow: none;
}

.@{layout-prefix-cls} {
    &-sider {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 60px, rgba(255, 255, 255, 0) 60px, rgba(255, 255, 255, 0));

        &-children {
            box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.25);
            border-radius: 0px 40px 40px 0px;
            background: #fff;
            min-height: 100vmin;
            height: 100vh;
            scrollbar-width: thin;
            scrollbar-gutter: stable;
            overflow: hidden;

            &:hover {
                overflow: auto visible;
            }
        }
    }
}

.ant-table-row {
    cursor: pointer;
}

.@{collapse-prefix-cls} {
    margin-bottom: 20px;

    &-header {
        &-text {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.@{dialog-prefix-cls} {
    &-title {}

    &-content {}

    &-close {
        &-x {}

        &:focus,
        &:hover {
            color: @icon-color-hover;
            text-decoration: none;
        }
    }

    &-header {
        text-align: center;
    }

    &-body {}

    &-footer {
        text-align: center;
    }

    &-open {}
}

.@{confirm-prefix-cls} {
    &-body {
        text-align: center;
        // .@{confirm-prefix-cls}-title {
        //     text-align: center;
        // }

        // .@{confirm-prefix-cls}-content {
        //     text-align: center;
        // }
    }

    .@{confirm-prefix-cls} &-btns {
        text-align: center;
    }
}

.site-page-header-ghost-wrapper {
    // margin-left: -40px;
    // padding-left: 40px;
    background: white;
}

.ant-btn-primary {
    background-color: #23B27C;
    border-color: #23B27C;
}

.ant-btn-cancel {

    background-color: #FA5359;
    border-color: #FA5359;
    color: #efefef
}

.ant-btn-cancel:hover {

    background-color: #f3383f;
    border-color: #f3383f;
    color: #efefef
}

// .ant-input{

// background: #FFFFFF;
// border: 0.75px solid #C1C0C0;
// border-radius: 10px;
// }

.@{descriptions-prefix-cls} {
    &-item {
        // vertical-align: middle;
        vertical-align: bottom;

        &-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: end;

            .@{descriptions-prefix-cls}-item-label {
                display: initial;
            }

            .@{descriptions-prefix-cls}-item-content {
                align-self: end;
            }
        }

        &-label {
            font-weight: 500;
        }
    }
}

.@{picker-prefix-cls} {
    &-panel-container {
        display: flex;
        flex-direction: row-reverse;
    }

    &-footer {
        min-width: min-content;
    }

    &-ranges {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        height: 100%;

        >li {
            display: block;
        }

        .@{picker-prefix-cls}-preset>.@{ant-prefix}-tag-blue {
            color: black;
            background: none;
            border: none;
            font-size: 0.8rem;
            display: block;
            border-radius: 0;
            margin: 0;
            padding: 0.25rem 1rem;

            &:hover {
                background-color: @blue-color;
                color: white;
            }
        }
    }
}

.@{tag-prefix-cls} {
    display: flex;
    font-size: 13px;
    padding: 5px 8px;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;

    &-close-icon {
        margin-left: 8px;
        font-size: inherit;
    }

    &.filter-tags {
        cursor: pointer;

        &:hover {
            .button-color(@primary-5; white; @primary-5);
        }
    }

    &-checkable {
        border: 1px @color-lightgray-3 solid;

        &-checked {
            background-color: @secondary-color;
        }
    }
}

.headerStats {
    margin: 1rem;

    .@{statistic-prefix-cls} {
        text-align: center;

        &.card {
            background: white;
            padding: 1rem;
            border-radius: 8px;
        }

        &:not(.card) {
            .@{statistic-prefix-cls} {
                &-title {
                    color: #333;
                }

                &-content {
                    color: #4178D1;
                    background-color: #E8F2FB;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 2;
                    min-width: 100%;
                    border-radius: 10px;
                    padding: 0 1.5rem;
                }
            }
        }

        &-content {

            &:has(.@{statistic-prefix-cls}-content-suffix),
            &:has(.@{statistic-prefix-cls}-content-prefix) {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }

        .status-styles-with-child(~'status', ~'.@{statistic-prefix-cls}-content', length(@statuses));
    }
}

.ant-comment {
    &:not(.ant-comment-form) {
        .ant-comment-content-detail {
            display: inline-block;
            padding: 8px 15px;
            border-radius: 10px;
            background: @color-lightgray-1;
        }
    }
}

.@{message-prefix-cls} {
    &-notice-content {
        padding: 1rem 2rem;
    }
}

.input() {

    &:focus,
    &-focused {
        box-shadow: 0 0 0 2px rgba(35, 177, 124, 0.2);
    }
}