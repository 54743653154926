.tableValue {
    padding: 8px;
    width: 100px;
    border-radius: 8px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;

}
.tableSpacing {
    width: 100%;

}

.formItem {
    width: 30%;
}

.tableRow {
    padding: 16px;
    border-bottom: 0.5px solid gray;

    &:last-child {
        text-align: right;
    }
}

.tableValues {
    padding: 16px;
    color: #646464;

    &:last-child {
        text-align: right;
    }
}
.headerValue {

    width: 100px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: #3d3d3e;
}

.actionBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clientOrderNumber {

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.greenButton {
    color: #5ac59d;
    border: 1px solid #5ac59d;
}

.redButton {
    color: #ef6870;
    border: 1px solid #ef6870;
}

.summaryBox {
    padding: 20px;
    margin: 16px 0px;
    background-color: #FAFAFA;
    border-radius: 16px;
}

.netAmount {
    padding: 16px;
    font-weight: 500;
    font-size: 18px;
    border-top: 0.5px solid gray;

    &:last-child {
        text-align: right;
    }
}