.outerWrapper{
  padding: 8px;
  border-radius: 8px;
  background-color: white;
}

.wrapper {
  display: flex;
  background: #fafafa;
  border-radius: 15px;
  padding: 29px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  
    .left {
      width: 40%;
      .imageText{
        font-size: 10px;
      }
      .image {
        width: 88%;
  
        img {
          border: 1px solid grey;
          border-radius: 8px;
          max-width: 100%;
          overflow: hidden;
        }
      }
    }
  
    .right {
      padding-top: var(--space-small);
      width: 100%;
  
      .specifications {
        background-color: #f2f2f2;
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin: 2rem auto;
        padding: 20px;
        margin: 10px;
        align-content: center;
        border-radius: 15px;
        .name {
          color: gray;
  
          &:after {
            content: ":";
          }
        }
  
        .value {
          font-weight: 500;
        }
      }
  
      .quantities {
        width: 100%;
        text-align: left;
        border-collapse: separate;
        border-spacing: 0 var(--space-small);
        color: var(--color-light-black);
  
        .quantityHeading {
          color: #000;
        }
  
        .quantityRow {
          display: grid;
          justify-items: stretch;
          padding: 0.8rem 1.2rem;
          grid-template-columns: repeat(4, minmax(max-content, 1fr));
  
          &:not(.quantityHeading) {
            cursor: pointer;
          }
  
          .quantityCheckbox {
            text-align: right;
          }
  
          &:has(input:checked) {
            box-shadow: 1px 1px 3px var(--color-green);
            border-radius: var(--radius-small);
          }
  
          input[type="checkbox"] {
            cursor: pointer;
          }
        }
      }
    }
  }
  
  .cell {
    padding-top: 10px;
  }
  
  .greenShadowBox {
    box-shadow: 2px 2px 5px 0px #40b37c;
    border-radius: 10px;
  }
  
  .tableHeadingPadding {
    padding: 16px;
  }
  
  .tableValuePadding {
    padding: 16px;
    font-size: 13px;
  }
  
  .addressInfo{
      font-weight: 550;
      font-size: 14px;
  }