.container {
    background: #f6f6f678;
    border-radius: 0px 10px 10px 0px;
    border-left: 3px #4178d1 solid;
    margin: 1em 0 1em 0;
    position: relative;

    &.primary {
        background: #fef7e7;
        border-color: #f5c53e;
        // &:hover{
        //     outline-color: #f5c53e;
        // }
    }

    // &:hover {
    //     outline: 1px #4178d1 solid;
    // }
    .body {
        padding: 1em;
    }

    .header {
        padding: 0.5em;
        border-bottom: 1px #d9d9d9 solid;
        display: flex;
        justify-content: flex-end;

        &.fullHeader {
            justify-content: space-between;

        }

        .primaryHeader {
            flex: 1;
        }

        .action {
            font-size: 15px;
            border-right: 1px #d9d9d9 solid;
            padding: 0 0.5em;

            &:last-child {
                border-right: none;
            }

            &:hover {
                color: #4178d1;
                cursor: pointer;
            }
        }
    }
}