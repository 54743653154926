$border: 1px #c1c0c0 solid;

.wrapper {
    .item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 1em;
        border-radius: 10px;
        margin-bottom: 2px;
        transition: transform 0.2s ease-in-out;
        cursor: pointer;

        &Hidden {
            cursor: initial;
        }

        // &:hover {
        //     transform: scale(1.01, 1.1);
        // }

        .itemLabel {
            //border-right: $border;
            padding: 5px 15px;
        }

        .itemValue {
            position: relative;

            .editAction {
                position: absolute;
                right: 10px;
                cursor: pointer;
            }
        }
    }

    .itemValue {
        color: rgba(0, 0, 0, 0.6);
    }

    &:not(.readonly) .item {
        background-color: white;
        border: $border;

        &:first-child {
            border-top: $border;
        }
    }

}

.specBottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem 0;
    gap: 1rem;
}