.FormWrapper{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1em;
    .FormField{
        &--TwoCol{
            grid-column: 2 / 2 span;
        }
        &--FullCol{
            grid-column: 1 / 5 span;
        }
    }
}

.ant-input[disabled] {
    color: #000;
    background-color: #eaeaea7a !important;}


    .customInput[disabled] {  
        background: none !important;
        background-color: transparent !important;
        border: none !important;
      }



      