.selectOpenProject {
    display: flex;
    flex-direction: column;
}

.secondHeader {
    display: flex;
}

.spacer {
    height: 10px;
}

.smallFont {
    font-size: 11px;
}

.confirmLetter {
    display: flex;
    flex-direction: column;
}

.files {
    &:empty {
        display: none;
    }

    display: flex;
    width: 100%;
    align-items: center;
    min-width: 0;
    padding: 16px;
    flex-wrap: wrap;
    background: #f6f6f6;
    align-content: flex-start;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;

    .label {
        width: 50%;
        text-align: start;
    }


    .file {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        flex-wrap: nowrap;
        /* align-content: flex-end; */
        align-items: center;
        margin-bottom: 16px;

        img {
            display: block;
            width: 100px;
            height: 100px;
            object-fit: cover;
            border: 0.5px solid gray;
            padding: 8px;
            background: white;
            border-radius: 8px;
        }
    }
}