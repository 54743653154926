.wrapper {
    margin: 1em auto;
    padding-bottom: 1em;

    &:not(:last-of-type) {
        border-bottom: 1px #c1c0c0 solid;
    }

    .header {
        display: grid;
        grid-template-columns: min-content 1fr fit-content(8ch);
        align-items: center;
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 2em;
        gap: 0.5rem;
        padding: 0 1rem;
    }
}