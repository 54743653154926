.files {
    &:empty {
        display: none;
    }

    display: flex;
    width: 100%;
    align-items: center;
    min-width: 0;
    padding: 16px;
    flex-wrap: wrap;
    background: #f6f6f6;
    align-content: flex-start;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;

    .label {
        width: 50%;
        text-align: start;
    }


    // .file {
    //     display: flex;
    //     flex-direction: row;
    //     width: 100%;
    //     justify-content: space-between;
    //     flex-wrap: nowrap;
    //     /* align-content: flex-end; */
    //     align-items: center;
    //     margin-bottom: 16px;

    //     img {
    //         display: block;
    //         width: 100px;
    //         height: 100px;
    //         object-fit: cover;
    //         border: 0.5px solid gray;
    //         padding: 8px;
    //         background: white;
    //         border-radius: 8px;
    //     }
    // }

    .file {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto auto;
        gap: 10px;
        align-items: center;
        width: 100%;

        .filePreview {
            grid-row: 1 / 4;
        }

        .fileComment {
            grid-column: 2;
            grid-row: 2;

            .label {
                width: 100%;
                font-weight: 500;
            }

            textarea:disabled {
                margin: 0;
                padding: 0;
            }
        }

        .fileActions {
            grid-column: 2;
            grid-row: 3;
        }

        img {
            display: block;
            width: 100px;
            height: 100px;
            object-fit: cover;
            border: 0.5px solid gray;
            padding: 8px;
            background: white;
            border-radius: 8px;
        }
    }
}