@import '../../styles/config';

.kanabanWrapper {
    height: 100%;
    // overflow: auto;

    height: 100%;
    overflow: auto;
    /* overflow: hidden; */
    max-height: calc(100vh - 130px);
    box-sizing: border-box;
    padding: 2px 0.5rem 0 0.5rem;
    scrollbar-width: stable;

    .scrollMap {
        position: absolute;
        bottom: 25px;
        right: 50px;
        width: 125px;
        height: 50px;
        background: #fff;
        display: flex;
        align-items: center;
        overflow: hidden;
        box-shadow: 4px 8px 14px 0 #091e4240;

        .scrollBackground {
            position: absolute;
            width: 100%;
            height: 100%;
            display: grid;
            grid-auto-flow: column;
            /* Force a single row with columns */
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
            gap: 2px;
            padding: 6px;

            .scrollMapColumn {
                background: $blue-light-color;
                height: 100%;
            }
        }

        .scrollIndicator {
            flex: 1 1;
            position: absolute;
            top: 0;
            width: 10%;
            background: rgba(0, 0, 0, 0.05);
            margin: 4px;
            border: 2px $blue-color solid;
            height: calc(100% - 8px);

            &:active {
                cursor: grabbing;
            }
        }
    }

}

.kanaban {
    display: grid;
    grid-gap: 10px;
    grid-auto-columns: 270px;
    grid-auto-flow: column;
    max-height: 100%;

    &Column {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &Vanish {
            display: none;
        }

        &Header {
            position: sticky;
            top: 0px;
            padding: 16px;
            border-left: 5px solid #4178D1;
            font-size: 14px;
            font-weight: 500;
            background-color: white;
            border-radius: 4px 8px 8px 4px;
            box-shadow: 0px 0px 8px 2px #D3D3D380;
            display: flex;
            align-items: center;
            z-index: 2;
        }

        &Title {
            /* Allow the element to grow and shrink */
            flex: 1;
            /* Prevent text wrapping */
            white-space: nowrap;
            /* Hide overflowing text */
            overflow: hidden;
            /* Display ellipsis for overflowed text */
            text-overflow: ellipsis;
        }


        &Count {
            /* Add styles for the always visible element */
            /* Adjust spacing between elements */
            margin-left: 10px;
        }

        &--footer {
            .payment {
                font-size: 16px;
                color: #4fa9e8;
            }

            .payment_value {
                font-weight: 700;
                color: #4fa9e8;
            }

        }

        .scrollToEnd {
            text-align: center;
            padding: 1rem;
            flex: 1;
        }
    }
}