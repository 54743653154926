.wrapper {
    .innerWrapper {
        display: grid;
        grid-template-columns: 60px auto;
        padding: 0;
        box-sizing: border-box;
    }

    .countryCode {
        .ant-select-selector {
            display: none;
        }
    }
}

.ant-select-selector {
    height: 31px !important;
}