.itemDetail{
    display: flex;
    background-color: #F9F9F9;
    border-radius: 16px;
    /* padding: 16px; */
    align-items: center;
    justify-content: space-between;

}
.imageBox{
    object-fit: cover;
    border: 1px solid;
    padding: 5px;
    width: 150px;
    height: 120px;
    border-radius: 6px;
    border: 0.5px solid gray;
    background: white;
    
}

.tabBody{
    background: #F9F9F9;
    padding: 32px;
    border-radius: 0px 16px 16px 16px;
}
.rejectTab {
    background: #FCEAE9;
    color: red;
    font-weight: 600;
}

.userPhoto{
display: flex;
flex-direction: row-reverse;
align-items: center;
}

.userInfo{
    line-height: 16px;
    position: absolute;
    text-align: center;
    margin: 16px 105px 0px 0px;

}
.name {
    font-size: 16px;

}
.type{
    font-size: 12px;
    color: gray;
}

.text{
    text-align: center;
    width: 103px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.imageName{
    text-align: start;
    width: 300px;
    overflow: hidden;
}

.bottomButton{
    display: flex;
    gap: 1em;
    align-items: center;
}