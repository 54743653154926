.header {
    // width: calc(100% - 170px);
    // margin-left: 170px;  
    // width: 100%;
    // position: sticky;
    // top: 0;
    // z-index: 8;
}

.secondaryheader {
    display: flex;
    justify-content: space-between;
    // max-width: calc(100% - 200px);
    align-items: center;
}