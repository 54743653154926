@import './../../styles/config';

.container {
    border: 1px solid $border-color-base;
    border-radius: $border-radius-large;
    background-color: white;

    .header {
        padding: 8px 15px;
        border-bottom: 1px $border-color-base solid;
        display: flex;
        gap: 1rem;

        &WithIcon {
            @extend .header;
            display: grid;
            gap: 1rem;
            grid-template-columns: min-content max-content;
        }
    }

    .content {
        padding: 15px;
    }

    .footer {
        padding: 8px 15px;
        border-top: 1px $border-color-base solid;
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &.blueBox {
        background-color: $blue-light-color;
        border-color: $blue-light-color;
    }

    &.grayBox {
        background-color: #f9f9f9c2;
        border-color: #f9f9f9c2;
    }

    &.grayBox2 {
        background-color: $gray-ligh2;
        border-color: $gray-ligh2;
        color: $secondary-color;
    }

    &.innerShadow {
        box-shadow: 1px 1px 6px 0px #D9D9D9 inset;
        background: #F6F6F6B0;
    }

    &.smallBox {
        border-radius: $border-radius-medium;

        .content {
            padding: 8px;
        }
    }

    &.miniBox {
        border-radius: $border-radius-small;

        .content {
            line-height: 1;
            padding: 8px;
        }
    }
}