.selectedValue .ant-select-selector{
    align-items: center;
    background-color: #f8edff;
    border-radius: 7px;
    color: #c054ff;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.3em 0.6em;
    gap: 0.4em;
    cursor: pointer;

}


