@import "./../../../styles/_config.scss";

.vendor {
    border-radius: 15px;
    background: rgba(249, 249, 249, 0.76);

    &:not(:last-child) {
        margin-bottom: 1em;
    }

    .vendorHeader {
        display: grid;
        grid-template-columns: min-content auto max-content;
        align-items: center;
        gap: 1em;
        padding: 1.4em;
        border-bottom: 1px $border-color-base solid;

        .vendorName {
            font-weight: bold;
            font-size: 13px;
        }

        .vendorActions {
            display: flex;
            gap: 0.5rem;
        }
    }

    .vendorPrices {
        padding: 0.7em;

        .quantityPricesHead {
            display: grid;
            grid-template-columns: repeat(8, 1fr);

            &Vat {
                grid-template-columns: repeat(9, 1fr);
            }
        }

        .vendorQuantityPrice {
            display: grid;
            grid-template-columns: repeat(8, 1fr);

            &Vat {
                grid-template-columns: repeat(9, 1fr);
            }
        }
    }
}