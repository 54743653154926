@import '../styles/config';

.stepIcon {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $secondary-color;
    background: #E8F8EE;
    color: $secondary-color;
    transition: background-color 0.3s, border-color 0.3s;

    &Active {
        @extend .stepIcon;
        // background-color: $secondary-color;
        // color: white;
        border: 3px solid $secondary-color;
        transform: scale(1.2);
    }
}