@import '../../styles/_config';

.loader {
    display: flex;
    position: relative;
    align-items: center;

    &:before {
        content: "";
        width: 1rem;
        height: 1rem;
        margin-right: 5px;
        border: 2px solid black;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}