.mainWrapper {
    border-radius: 15px;
    background: rgba(249, 249, 249, 0.76);
    padding: 15px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    // gap: 2rem;

    &-Header {
        display: flex;
        justify-content: flex-end;
    }
}

.fieldsWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;

    &:not(:last-child) {
        padding-bottom: 1.5rem;
    }

    &--invoiceLine1 {
        @extend .fieldsWrapper;
        grid-template-columns: 2fr 1fr 1fr;
    }

    &--invoiceLine2 {
        @extend .fieldsWrapper;
        grid-template-columns: repeat(7, 1fr);
    }

    &--invoiceLine3 {
        @extend .fieldsWrapper;
        grid-template-columns: 1fr;
    }

    &--invoiceLine4 {
        @extend .fieldsWrapper;
        grid-template-columns: 1fr 1fr;
    }

    &--invoiceLine5 {
        @extend .fieldsWrapper;
        grid-template-columns: 1fr 1fr 1.5fr;
    }

    .wFull {
        width: 100%;
    }


    .inputField {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: flex-start;
        text-wrap: nowrap;

        &--right {
            @extend .inputField;
            align-items: flex-end;
        }

        &--span2 {
            @extend .inputField;
            grid-column: span 2;
        }

        &--span3 {
            @extend .inputField;
            grid-column: span 3;
        }

    }

    .description {
        display: grid;
        grid-template-columns: 40% auto;
        gap: 0.5rem;

        &--span2 {
            @extend .description;
            grid-column: span 2;
        }

        &--span3 {
            @extend .description;
            grid-column: span 3;
        }

        .DescriptionTitle {
            font-weight: bold;
            text-wrap: nowrap;

            &::after {
                content: ':'
            }
        }
    }

    &.invoiceTotals {
        background: rgba(249, 249, 249, 0.76);
        padding: 2rem;
        gap: 0.5rem 1.5rem;
    }
}

.actionsWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    margin: 15px auto;
}