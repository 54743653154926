@use "./../../css/button";
@use "./../../css/box";
@import "./../../styles/config";

.vendorTitle {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  display: inline-block;
}

.projectItem {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  padding-left: 2rem;

  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;

    .productInfo {
      grid-column-start: 1;
      justify-self: start;
      align-self: flex-start;

      .productImage {
        border: 1px var(--color-silver-line) solid;
        border-radius: var(--radius-small);
        max-width: 100%;
        overflow: hidden;
      }
    }

    .progressBar {
      display: flex;
    }


  }

  .infoBox {
    @extend .box--blue;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 15px;

    .title {
      color: #000;
      font-size: 0.8rem;
      font-weight: 500;
    }

    .value {
      color: $blue-color;
      font-weight: 500;
    }
  }

  .tasksBox {
    display: flex;
    flex-direction: column;
    gap: var(--space-large);

    .task {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: var(--fontsize-medium);

      .taskContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-small);
      }

      .taskAction {
        display: flex;
        gap: var(--space-xxsmall);

        .button {
          @extend .button--primary;
          font-size: var(--fontsize-normal);
          font-weight: 500;
          white-space: nowrap;
        }
      }

      .daysLeftBox {
        border-radius: var(--radius-extra-small);
        background: var(--color-gray-1);
        color: var(--color-red);
        display: inline-grid;
        grid-template-columns: max-content min-content max-content;
        align-items: center;
        gap: 5px;
        padding: 0.5rem;

        .daysLeftNum {
          display: initial;
          font-size: 2.5rem;
          font-weight: 700;
        }

        .daysLeftSuffix {
          display: initial;
          font-weight: 700;
          text-transform: uppercase;
        }

        .daysLeftText {
          align-self: center;
          margin-left: var(--space-small);
        }
      }
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.stepBox {
  margin-top: 20px;
  border-radius: 10px;
  border: 0.5px solid $border-color-base;
  background-color: white;
  padding: 30px;

  border: 1px $border-color-base solid;
  padding: 15px;
  position: relative;
  margin-top: 46px;
}

.redBox {
  padding: 8px;
  background: #fdeeed;
  text-align: initial;
  border-radius: 16px;
}

.blueBox {
  padding: 8px;
  background: #e8f2fb;
  text-align: initial;
  border-radius: 16px;
}

.cancelModal {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 14px 9px #e5e5e5 inset;
  text-align: left;

}
.blueDot {
  color: rgb(0, 51, 255);
  font-size: 32px;
  margin-left: 16px;
}

.redDot {
  color: red;
  font-size: 32px;
  margin-left: 16px;
}

.text {
  color: black;
  font-size: 15px;
}

.button {
  @extend .button--primary;
  font-size: var(--fontsize-normal);
  font-weight: 500;
  white-space: nowrap;
}

.imageBox {
  border: 1px solid lightgray;
  margin: 16px;
  box-shadow: 0px 0px 12px 4px lightgray;
}

.innerTaskBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.productInfo {
  padding: 16px;
}