.innerWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 6%;
    gap: 1.5rem;
    // max-width: calc(100% - 200px);
    margin-left: auto;

    .header {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        
    }

    .footer {
        display: flex;
        justify-content: space-between;
    }
}
.header {

    width: 100%;
    position: fixed;
    z-index: 8;
    text-align: right;
    top: 64px;
    padding-top: 10px;
    right: 30px;
    background: #f0f2f5;


}